import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "mb-5" }
const _hoisted_2 = { class: "mt-4" }
const _hoisted_3 = { class: "mt-4" }
const _hoisted_4 = { class: "mt-4" }
const _hoisted_5 = { class: "mt-4" }
const _hoisted_6 = { class: "mt-4 pt-1" }
const _hoisted_7 = {
  key: 0,
  class: "row"
}
const _hoisted_8 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvatarUpload = _resolveComponent("AvatarUpload")!
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppInputsValidationProvider = _resolveComponent("AppInputsValidationProvider")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, _mergeProps({
    title: _ctx.title,
    opened: _ctx.opened
  }, _ctx.$attrs, { onHide: _ctx.hide }), {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_AvatarUpload, { onFileChanged: _ctx.fileUpload }, null, 8, ["onFileChanged"])
        ]),
        _createVNode(_component_AppInputsValidationProvider, {
          "shake-tree": _ctx.parentTouched,
          onChangeErrorState: _ctx.onChangeErrorState
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_AppInput, {
                id: "create-user-name",
                modelValue: _ctx.firstName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstName) = $event)),
                "input-type": "material",
                label: "First Name",
                type: "text",
                "validation-type": "name",
                name: "name"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_AppInput, {
                id: "create-user-name",
                modelValue: _ctx.lastName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lastName) = $event)),
                "input-type": "material",
                label: "Last Name",
                type: "text",
                "validation-type": "name",
                name: "name"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_AppInput, {
                id: "create-user-email",
                modelValue: _ctx.userEmail,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userEmail) = $event)),
                "input-type": "material",
                label: "Email",
                type: "email",
                "validation-type": "email",
                name: "email"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_AppInput, {
                id: "crete-user-password",
                modelValue: _ctx.userPassword,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.userPassword) = $event)),
                type: "password",
                "input-type": "material",
                label: "Password",
                name: "new-password",
                "validation-type": "password"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_AppInput, {
                id: "crete-user-password-repeat",
                modelValue: _ctx.repeatPassword,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.repeatPassword) = $event)),
                type: "password",
                "input-type": "material",
                label: "Repeat Password",
                "validation-type": "equal",
                "data-equal-to": "crete-user-password",
                name: "new-password-repeat"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _: 1
        }, 8, ["shake-tree", "onChangeErrorState"]),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.canCancel)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", {
                  class: _normalizeClass(["col-6", _ctx.hide])
                }, [
                  _createVNode(_component_AppButton, {
                    type: "secondary",
                    size: "xl",
                    disabled: _ctx.validation,
                    loading: _ctx.loading,
                    onClick: _ctx.submit
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.buttonTextUpdated), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "loading", "onClick"])
                ], 2),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_AppButton, {
                    type: "default-transparent",
                    size: "xl",
                    onClick: _ctx.hide
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Cancel ")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]))
            : (_openBlock(), _createBlock(_component_AppButton, {
                key: 1,
                type: "secondary",
                size: "xl",
                disabled: _ctx.validation,
                loading: _ctx.loading,
                onClick: _ctx.submit
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.buttonTextUpdated), 1)
                ]),
                _: 1
              }, 8, ["disabled", "loading", "onClick"]))
        ])
      ], 32)
    ]),
    _: 1
  }, 16, ["title", "opened", "onHide"]))
}